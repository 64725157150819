<template>
  <div class="lxp-dashboard__discover-courses">
    <b-row class="h-100" v-if="isLoading === true">
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-3"
        v-for="index in 4"
        :key="index"
      >
        <shimmer
          variant="landing-course-card"
          style="width: 100%; height: 100%;"
        />
      </b-col>
    </b-row>
    <b-row class="m-0" v-else-if="course">
      <b-col class="p-0">
        <div class="ld-sec__tabs mt-0">
          <b-row class="m-0">
            <div class="d-block w-100">
              <pagination
                :isLoadMore="
                  (!isCoursesLoading || courses.length > 0) && isLoadMore
                "
                @onLoadMore="$router.push({ name: 'course-discovery' })"
                viewAllText="general.view_all_programmes"
              >
                <template v-slot:course-card>
                  <div class="ld-sec__grid-layout">
                    <div
                      class="cd-list--cards"
                      v-for="(course, index) in courses"
                      :key="index"
                    >
                      <CourseCard
                        :course="course"
                        :org="course.org"
                        :index="`course-card-${index}`"
                        :showChar="60"
                        :courseTitle="course.title"
                        :pacing="course.pacing"
                        :courseLanguage="course.language"
                        @click="$emit('click', course)"
                        @orgClick="onOrgClick(course)"
                        layout="h"
                        :courseImage="
                          course.image_url
                            ? course.image_url
                            : require('@/assets/images/illustration/course-illustration.svg')
                        "
                      ></CourseCard>
                    </div>
                  </div>
                </template>
                <template v-slot:card-loader>
                  <b-row
                    v-if="isCoursesLoading && allCourses.length === 0"
                    class="cd-main__loader--wrapper"
                  >
                    <b-col
                      md="3"
                      class="mb-3"
                      v-for="c in [1, 2, 3, 4]"
                      :key="c"
                    >
                      <CourseCardLoader />
                    </b-col>
                  </b-row>
                </template>
              </pagination>
              <div
                class="
                  tab-coming-soon
                  d-flex
                  align-items-center
                  justify-content-center
                "
                v-if="allCourses.length == 0 && !isCoursesLoading"
              >
                <div class="text-center">
                  <img
                    src="@/assets/images/not-found/no-results-found.svg"
                    alt="no result"
                    width="auto"
                    height="auto"
                  />
                  <p class="mt-3 mb-1">{{ $t("search.empty.title") }}</p>
                  <p>
                    {{ $t("search.empty.subtitle") }}
                  </p>
                </div>
              </div>
            </div>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import getCoursesMixin from "@/components/SearchCourse/mixins/getCourses";
import Pagination from "@/components/WrapperComponents/Pagination.vue";
import CourseCardLoader from "@/components/CourseCardLoader.vue";
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";
export default {
  mixins: [getCoursesMixin, redirectToLearningSpace],
  components: {
    Pagination,
    CourseCardLoader
  },

  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    course: {
      type: Object
    }
  },
  methods: {
    onOrgClick(c) {
      this.navigateToLearningSpace(c.org);
    }
  }
};
</script>
<style lang="scss" scoped>
.lxp-dashboard__discover-courses {
  .ld-sec__tabs {
    margin-bottom: 20px;
    .d-block {
      padding: 0 0 0 15px;
      .ld-sec__grid-layout {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(clamp(25% - 24px, 218px, 100%), 1fr)
        );
        gap: 24px;
      }
    }
  }
}
@media screen and (min-width: 482px) and (max-width: 1100px) {
  .lxp-dashboard__discover-courses {
    .ld-sec__tabs {
      .d-block {
        padding: 0;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .lxp-dashboard__discover-courses {
    .ld-sec__tabs {
      .d-block {
        padding: 0;
      }
    }
  }
}
</style>
